import React from "react";
import PropTypes from "prop-types";

export default function FullWidthImage(props) {
  const {
    title,
    subheading,
  } = props;

  return (
    <React.Fragment>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundPosition: `left bottom`,
          backgroundAttachment: `fixed`,
        }}
      >
        <div
          className="has-text-centered"
          style={{
            display: 'flex',
            height: '150px',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >

        {(title || subheading) && (
          <div>
            {/* Any content here will be centered in the component */}
            {title && (
              <h1
                className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                  color: 'darkmagenta',
                  lineHeight: '1',
                  padding: '0.25em',
                }}
              >
                {title}
              </h1>

            )}
            {subheading && (
              <h3
                className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                style={{
                  color: 'black',
                  lineHeight: '1',
                  padding: '0.25em',
                  // marginTop: "0.5rem",
                }}
              >
                {subheading}
              </h3>
            )}
          </div>
        )}
        </div>
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.string,
};
